import axios from "axios";

// const API_URL = "http://localhost:3040/api/";
const API_URL = "https://api-dca-acceron.delnie.com/api/";

let stateAccessToken: any = null;

export function setAccessToken(token: string) {
  stateAccessToken = token;
}

let accessToken = stateAccessToken || localStorage.getItem("accessToken");

async function resolve(promise: any) {
  const resolved = {
    data: null,
    error: null,
  };
  try {
    resolved.data = await promise;
  } catch (e: any) {
    resolved.error = e;
  }
  return resolved;
}

export async function get(path: string, urlParams: object) {
  let accessToken = stateAccessToken || localStorage.getItem("accessToken");
  let FULL_URL = API_URL + path;
  let headers = { Authorization: accessToken, organization_id: 1002 };
  let options = { headers: headers, params: urlParams };
  return axios.get<any>(FULL_URL, options);
}

export async function awsget(path: string, urlParams: object) {
  let FULL_URL = API_URL + path;
  let headers = { Authorization: accessToken, organization_id: 1002 };
  let options = { headers: headers, params: urlParams };
  return axios.get<any>(FULL_URL, options);
}

export async function awsupload(path: string, data: object) {
  let accessToken = stateAccessToken || localStorage.getItem("accessToken");
  let url = API_URL + path;
  console.log(path);
  console.log(url);
  let headers = { Authorization: accessToken, organization_id: 1002 };

  let options = { headers: headers };
  return await resolve(axios.post(url, data, options).then((res) => res.data));
}

export async function post(path: string, data: object) {
  let accessToken = stateAccessToken || localStorage.getItem("accessToken");
  let FULL_URL = API_URL + path;
  let headers = { Authorization: accessToken };
  let options = { headers: headers };
  return axios
    .post(FULL_URL, data, options)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
      if (err.statusCode == 401) {
        localStorage.setItem("accessToken", "");
      }
    });
}

// export async function sendNotifyEmail(path: string, data: object) {
//   let FULL_URL = NOTIFY_API_URL + path;
//   let headers = { Authorization: accessToken };
//   let options = { headers: headers };
//   return axios
//     .post(FULL_URL, data, options)
//     .then((res) => {
//       return res.data;
//     })
//     .catch((err) => {
//       console.log(err);
//       if (err.statusCode == 401) {
//         localStorage.setItem("accessToken", "");
//       }
//     });
// }
export async function patch(path: string, data: object) {
  let accessToken = stateAccessToken || localStorage.getItem("accessToken");

  let FULL_URL = API_URL + path;
  let headers = { Authorization: accessToken };
  let options = { headers: headers };
  return axios
    .patch(FULL_URL, data, options)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
}

export async function put(path: string, data: object) {
  let accessToken = stateAccessToken || localStorage.getItem("accessToken");
  let FULL_URL = API_URL + path;
  let headers = { Authorization: accessToken };
  let options = { headers: headers };
  return axios
    .put(FULL_URL, data, options)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
}

export async function del(path: string) {
  let accessToken = stateAccessToken || localStorage.getItem("accessToken");

  let FULL_URL = API_URL + path;
  let headers = { Authorization: accessToken };
  let options = { headers: headers };
  return axios
    .delete(FULL_URL)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
}

export async function login(path: string, data: object) {
  let FULL_URL = API_URL + path;
  let headers = { Authorization: accessToken };
  let options = { headers: headers };
  return axios
    .post(FULL_URL, data, options)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
      if (err.statusCode == 401) {
        localStorage.setItem("id_token", "");
      }
    });
}

export async function getProfile() {
  // const navigate = useNavigate();
  let accessToken = stateAccessToken || localStorage.getItem("accessToken");

  let FULL_URL = API_URL + "getProfile";
  let headers = { Authorization: accessToken };
  let options = { headers: headers };
  console.log(FULL_URL);
  console.log(headers);

  console.log(options);

  return axios
    .get(FULL_URL, options)
    .then((res) => {
      console.log(res.data);
      return res.data;
    })
    .catch((err) => {
      console.log(err);
      if (err && accessToken) {
        localStorage.setItem("accessToken", "");
        // navigate("/login");
        // window.location.replace("/login");
      }
    });
}

export async function register(path: string, data: object) {
  let FULL_URL = API_URL + path;
  let headers = { Authorization: accessToken };
  let options = { headers: headers };
  return axios
    .post(FULL_URL, data, options)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
      if (err.statusCode == 401) {
        localStorage.setItem("id_token", "");
      }
    });
}

const fileServerAuthKey =
  "CARRIEfMA0GCSqGSIb3DQEBAQdqDup1pgSc0tQUMQUAA4GNADCBiQKBgQD3apAg6H2iUPLOADSERVER";
export async function upload(path: any, data: any) {
  let accessToken = "Bearer " + localStorage.getItem("accessToken");
  let url = API_URL + path;
  let headers = {
    authorization: fileServerAuthKey,
    "session-token": accessToken,
    source: "pwa",
  };
  let options = { headers: headers };
  return await resolve(axios.post(url, data, options).then((res) => res.data));
}
