import React, { useEffect, useRef } from "react";
import axios from "axios";
import "./Table.scss";
import moment from "moment";
import { awsget, get } from "../../../utils/api";
import Chip from "@mui/material/Chip";
import Pagination from "../Pagination/Pagination";
import { Link, useParams } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import TableAddNewIcon from "../../assets/images/Tabledata/add-new-btn.svg";
import TableEditIcon from "../../assets/images/Tabledata/edit-btn.svg";
import MaterialPagination from "../MaterialPagination/MaterialPagination";
import FieldSettingsIcon from "../../assets/images/Tabledata/field_settings_icon.svg";
import ColumnFieldSelect from "../ColumnFieldSelect";

import { Button, TableSortLabel } from "@mui/material";

function Tabledata(props: any) {
  const containerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    // getnewsImage('hh')
  }, []);

  console.log("props.table", props);

  let data = props.data;
  console.log("data----------", data);
  console.log("folderame", props.folderName);
  let dataKeys: any[] = [];
  if (props.fields && props.fields.length > 0) {
    props.fields.map((e: any) => {
      if (e.coloumn_status) {
        dataKeys.push(e.key);
      }
    });
    dataKeys.push("actions");
  } else if (props.columns) {
    dataKeys = props.columns;
  } else if (data[0]) {
    dataKeys = Object.keys(data[0]);
    console.log("data", data);
    console.log("dataKeys", dataKeys);
  }
  console.log("tojo", dataKeys);

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const showRightSideBar = (rowData: any) => {
    // if (props.rightSidebar) {
    //   props.showRightSidebar({status:false,data:rowData});
    // } else {
    props.showRightSidebar({ status: true, data: rowData });
    // }
  };

  const setDetailViewModal = (rowData: any) => {
    console.log({ status: true, id: rowData[props.primary_id] });
    props.setDetailViewModal({ status: true, id: rowData[props.primary_id] });
  };

  const handleInvoiceEdit = (e: any) => {
    props.handleInvoiceEdit(e);
  };

  const handleEdit = (e: any) => {
    props.handleEdit(e);
  };

  const getDirection = (key: string, sortBy: any) => {
    if (sortBy[key] == -1) {
      return "desc";
    } else {
      return "asc";
    }
  };
  const getSortKey = (sortBy: any) => {
    let sortByAr = Object.keys(sortBy);
    return sortByAr[0];
  };

  // const handleWheel = (e: React.WheelEvent<HTMLDivElement>) => {
  //   const container = containerRef.current;
  //   if (container && e.deltaMode === 0) {
  //     container.scrollLeft += e.deltaY;
  //     e.preventDefault();
  //   }
  // };

  const handleWheel = (e: React.WheelEvent<HTMLDivElement>) => {
    const container = containerRef.current;
    if (container && e.deltaMode === 0) {
      const maxScrollLeft = container.scrollWidth - container.clientWidth;
      const shouldPreventScroll =
        (container.scrollLeft === 0 && e.deltaY < 0) ||
        (container.scrollLeft === maxScrollLeft && e.deltaY > 0);
      if (shouldPreventScroll) {
        e.preventDefault();
      }
    }
  };

  const getnewsImage = (imageName: any) => {
    let folderName = props.foldername;
    let bucketname = "acceron-private";
    console.log("getDpImage", "called getprofile");
    awsget(
      "api/s3FileDownload/" + "tamil-sangam" + "/" + "news" + "/" + "dp.jpg",
      {}
    ).then((res: any) => {
      console.log(res);
      if (res && res.data != undefined) {
        return res.data.url;
      } else {
        return null;
      }
    });
  };

  const handleAddKeys = () => {};
  const handleCheckbox = (e: any) => {
    console.log(e[props.primary_id]);
    console.log(props);
    const checkedItems = [...props.checked];
    let index = checkedItems.indexOf(e[props.primary_id]);
    if (index !== -1) {
      checkedItems.splice(index, 1);
    } else {
      checkedItems.push(e[props.primary_id]);
    }
    props.setChecked(checkedItems);
    console.log("props.checked", checkedItems);
  };

  console.log("props.checked", props.checked);

  const handleCheckboxAll = () => {
    props.setIsHeaderChecked(!props.isHeaderChecked);
    if (props.isHeaderChecked) {
      props.checked.splice(0, props.checked.length);
    } else {
      data.map((e: any) => {
        props.checked.push(e[props.primary_id]);
      });
    }
    console.log("props.setChecked", props.isHeaderChecked, props.checked);
  };
  console.log("props.setChecked", props.isHeaderChecked, props.checked);

  return (
    <div
      className="row invoices-table-container"
      ref={containerRef}
      onWheel={handleWheel}
    >
      <div
        className="col-xs-12"
        style={{ paddingLeft: "0", paddingRight: "0" }}
      >
        <table className="table table-striped invoices-table">
          <thead className="thead-light thead-main">
            <tr>
              <>
                <Checkbox
                  checked={props.isHeaderChecked}
                  className="d-flex"
                  {...label}
                  onChange={handleCheckboxAll}
                />
                {dataKeys.map((e: any) => {
                  return (
                    <th scope="col" className="table-headings">
                      <div>
                        <TableSortLabel
                          // active={orderBy === headCell.id}
                          active={e == getSortKey(props.sortBy)}
                          direction={getDirection(e, props.sortBy)}
                          // direction={orderBy === headCell.id ? order : "asc"}
                          onClick={(event) => props.handleSortBy(e)}
                        >
                          {/* {props.sortBy === 'asc' ? (
                            <ArrowUpwardIcon />
                          ) : (
                            <ArrowDownwardIcon />
                          )} */}
                          <span>
                            {e.includes("created_by_name")
                              ? e.replace("created_by_name", "Created By")
                              : e.includes("client_address")
                              ? e.replace("client_address", "Address")
                              : e.includes("del")
                              ? e.replace("del", "Del")
                              : e.includes("purchase_advance_amount")
                              ? e.replace(
                                  "purchase_advance_amount",
                                  "Purchase Advance"
                                )
                              : e.includes("expense_group_name")
                              ? e.replace("expense_group_name", "Expense Group")
                              : e.includes("expense_group_id")
                              ? e.replace("expense_group_id", "Bill Id")
                              : e.includes("TO_From")
                              ? e.replace("TO_From", "To/From")
                              : e.replaceAll("_", " ").toLowerCase()}
                          </span>
                        </TableSortLabel>
                      </div>
                    </th>
                  );
                })}
                <th>
                  <ColumnFieldSelect
                    className="fields-dropdown"
                    onClick={props.onClick}
                    buttonClass={props.buttonClass}
                    imagePath={FieldSettingsIcon}
                    color={props.color}
                    handleFieldStatus={props.handleFieldStatus}
                    fields={props.fields}
                    // customElement={props.customElement}
                  />
                </th>
              </>
            </tr>
          </thead>
          <tbody>
            {data &&
              data.length > 0 &&
              data.map((e: any) => {
                return (
                  // <tr onClick={() => showRightSideBar(e)} key={e.id}>
                  <tr
                    // onClick={() => setDetailViewModal(e)}
                    key={e[props.primary_id]}
                  >
                    {/* <th scope="row" key={e.id}>
                      {e.client_id}
                    </th> */}
                    <td className="text-center">
                      {" "}
                      <Checkbox
                        className="form-check-input"
                        checked={props.checked.includes(e[props.primary_id])}
                        onChange={() => handleCheckbox(e)}
                        {...label}
                        sx={{
                          paddingLeft: "13px",
                        }}
                      />
                    </td>
                    {dataKeys.map((e2: any) => {
                      let value = e[e2];
                      if (Array.isArray(value) && value.length > 0) {
                        const keys = Object.keys(value[0]);
                        value = (
                          <table className="table table-striped invoices-table">
                            <thead>
                              <tr>
                                {keys.map((key, i) => (
                                  <th key={i}>{key}</th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {value.map((row, i) => (
                                <tr key={i}>
                                  {keys.map((key, j) => (
                                    <td key={j}>{row[key]}</td>
                                  ))}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        );
                        // } else if (e2.includes("images_lng")) {
                        //   const imageSrc = value.en
                        //     ? value.en
                        //     : value.pl
                        //     ? value.pl
                        //     : value.ta
                        //     ? value.ta
                        //     : null;

                        //   return imageSrc ? (
                        //     <img
                        //       src={imageSrc}
                        //       alt="Image"
                        //       className="news-details-image"
                        //     />
                        //   ) : null;
                      } else if (
                        e2.includes("album_name_lng") ||
                        e2.includes("title_lng") ||
                        e2.includes("content_lng")
                      ) {
                        const values = [];
                        if (value.en) {
                          values.push(
                            <div className="d-flex flex-column align-items-center me-3">
                              <span>English</span>
                              <p>{value.en}</p>
                            </div>
                          );
                        }
                        if (value.pl) {
                          values.push(
                            <div className="d-flex flex-column align-items-center me-3">
                              <span>Polish</span>
                              <p>{value.pl}</p>
                            </div>
                          );
                        }
                        if (value.ta) {
                          values.push(
                            <div className="d-flex flex-column align-items-center me-3">
                              <span>Tamil</span>
                              <p>{value.ta}</p>
                            </div>
                          );
                        }
                        return values.length > 0 ? (
                          <td className="news-table-images d-flex">{values}</td>
                        ) : (
                          <td></td>
                        );
                      } else if (e2.includes("images_lng")) {
                        const images = [];
                        if (value.en) {
                          images.push(
                            <div className="d-flex flex-column align-items-center me-3">
                              <span>English</span>
                              <img
                                key="en"
                                src={value.en}
                                alt="Image"
                                // className="news-details-image"
                                height="60px"
                                width="60px"
                              />
                            </div>
                          );
                        }
                        if (value.pl) {
                          images.push(
                            <div className="d-flex flex-column align-items-center me-3">
                              <span>Polish</span>
                              <img
                                key="pl"
                                src={value.pl}
                                alt="Image"
                                // className="news-details-image"
                                height="60px"
                                width="60px"
                              />
                            </div>
                          );
                        }
                        if (value.ta) {
                          images.push(
                            <div className="d-flex flex-column align-items-center me-3">
                              <span>Tamil</span>
                              <img
                                key="ta"
                                src={value.ta}
                                alt="Image"
                                // className="news-details-image"
                                height="60px"
                                width="60px"
                              />
                            </div>
                          );
                        }
                        return images.length > 0 ? (
                          <td className="news-table-images d-flex">{images}</td>
                        ) : (
                          <td></td>
                        );
                      } else if (e2.includes("thumbnail")) {
                        const images = [];
                        if (value.image_url) {
                          images.push(
                            <div className="d-flex flex-column align-items-center me-3">
                              <img
                                key="image_url"
                                src={value.image_url}
                                alt="Image"
                                // className="news-details-image"
                                height="60px"
                                width="60px"
                              />
                            </div>
                          );
                        }
                        return images.length > 0 ? (
                          <td className="news-table-images d-flex">{images}</td>
                        ) : (
                          <td></td>
                        );
                      } else if (e2.includes("content_lng")) {
                        const content = [];
                        if (value.en) {
                          content.push(
                            <div className="d-flex flex-column align-items-center me-2">
                              <span>
                                <strong>English</strong>
                              </span>
                              <span className="content-title-ellipsis">{`${value.en.slice(
                                0,
                                15
                              )}...`}</span>
                            </div>
                          );
                        }
                        if (value.pl) {
                          content.push(
                            <div className="d-flex flex-column align-items-center me-2">
                              <span>
                                <strong>Polish</strong>
                              </span>
                              <span className="content-title-ellipsis">{`${value.pl.slice(
                                0,
                                15
                              )}...`}</span>
                            </div>
                          );
                        }
                        if (value.ta) {
                          content.push(
                            <div className="d-flex flex-column align-items-center me-2">
                              <span>
                                <strong>Tamil</strong>
                              </span>
                              <span className="content-title-ellipsis">{`${value.ta.slice(
                                0,
                                15
                              )}...`}</span>
                            </div>
                          );
                        }
                        return content.length > 0 ? (
                          <td scope="col">
                            <div className="news-details-images d-flex">
                              {content}
                            </div>
                          </td>
                        ) : null;
                      } else if (e2.includes("title_lng")) {
                        const title = [];
                        if (value.en) {
                          title.push(
                            <div className="d-flex flex-column align-items-center me-2">
                              <span>
                                <strong>English</strong>
                              </span>
                              <span className="content-title-ellipsis">{`${value.en.slice(
                                0,
                                15
                              )}...`}</span>
                            </div>
                          );
                        }
                        if (value.pl) {
                          title.push(
                            <div className="d-flex flex-column align-items-center me-2">
                              <span>
                                <strong>Polish</strong>
                              </span>
                              <span className="content-title-ellipsis">{`${value.pl.slice(
                                0,
                                15
                              )}...`}</span>
                            </div>
                          );
                        }
                        if (value.ta) {
                          title.push(
                            <div className="d-flex flex-column align-items-center me-2">
                              <span>
                                <strong>Tamil</strong>
                              </span>
                              <span className="content-title-ellipsis">{`${value.ta.slice(
                                0,
                                15
                              )}...`}</span>
                            </div>
                          );
                        }
                        return title.length > 0 ? (
                          <td>
                            <div className="news-details-images d-flex">
                              {title}
                            </div>
                          </td>
                        ) : null;
                      } else if (e2.includes("title")) {
                        let title = [];
                        title.push(
                          <td className="content-title-ellipsis">{`${value.slice(
                            0,
                            30
                          )}...`}</td>
                        );
                        return title.length > 0 ? <>{title}</> : <td></td>;
                      } else if (e2.includes("content")) {
                        let content = [];
                        content.push(
                          <td className="content-title-ellipsis">{`${value.slice(
                            0,
                            30
                          )}...`}</td>
                        );
                        return content.length > 0 ? <>{content}</> : <td></td>;
                      } else if (e2.includes("images")) {
                        let images: any = [];
                        value.forEach((imageObj: any) => {
                          images.push(
                            <img
                              src={imageObj.s3Url}
                              // alt={imageObj.alt}
                              className="image"
                            />
                          );
                        });
                        return images.length > 0 ? <>{images}</> : <td></td>;
                      } else if (e2.includes("resume_url")) {
                        value = (
                          <div>
                            <Link
                              to={e.resume_url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Button
                                variant="contained"
                                sx={{
                                  color: "#000000",
                                  bgcolor: "#47A025",
                                  " &:hover": {
                                    backgroundColor: "#2E933C",
                                  },
                                  minWidth: 0,
                                  padding: "5px 10px",
                                  height: "28px",
                                  fontSize: "12px",
                                }}
                              >
                                Download
                              </Button>
                            </Link>
                          </div>
                        );
                      } else if (e2.includes("document_url")) {
                        value = (
                          <div>
                            <Link
                              to={e.document_url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Button
                                variant="contained"
                                sx={{
                                  color: "#000000",
                                  bgcolor: "#47A025",
                                  " &:hover": {
                                    backgroundColor: "#2E933C",
                                  },
                                  minWidth: 0,
                                  padding: "5px 10px",
                                  height: "28px",
                                  fontSize: "12px",
                                }}
                              >
                                Download
                              </Button>
                            </Link>
                          </div>
                        );
                      } else if (e2.includes("date")) {
                        value = moment(value).format("DD-MMM-YYYY");
                      }
                      // else if (e2.includes("del")) {
                      //   value =
                      //     e.del === 0 ? (
                      //       <Chip
                      //         label="Active"
                      //         color="primary"
                      //         sx={{
                      //           height: "20px",
                      //           bgcolor: "rgba(135, 216, 106, 0.32)",
                      //           color: "#0A8442",
                      //         }}
                      //       />
                      //     ) : (
                      //       <Chip
                      //         label="Cancelled"
                      //         color="primary"
                      //         sx={{
                      //           height: "20px",
                      //           bgcolor: "rgba(216, 106, 106, 0.32)",
                      //           color: "#840A0A",
                      //         }}
                      //       />
                      //     );
                      // }
                      else if (e2 == "status") {
                        value =
                          e.status === "inactive" ? (
                            <Chip
                              label="Inactive"
                              color="primary"
                              sx={{
                                height: "20px",
                                bgcolor: "rgba(216, 106, 106, 0.32)",
                                color: "#840A0A",
                              }}
                            />
                          ) : (
                            <Chip
                              label="Active"
                              color="primary"
                              sx={{
                                height: "20px",
                                bgcolor: "rgba(135, 216, 106, 0.32)",
                                color: "#0A8442",
                              }}
                            />
                          );
                      } else if (e2.includes("actions")) {
                        value = (
                          <div className=" d-flex">
                            <button
                              className="btn"
                              // onClick={(e) => handleInvoiceEdit(e)}
                            >
                              <Link
                                to={props.detailLink + e[props.primary_id]}
                                // onClick={(e) => handleInvoiceEdit(e)}
                              >
                                <img src={TableAddNewIcon} alt="" />
                              </Link>
                            </button>
                            {props.module !== "general_enquiry" && (
                              <button className="btn">
                                <Link
                                  to={props.editLink + e[props.primary_id]}
                                  // onClick={() => handleEdit(e)}
                                >
                                  <img src={TableEditIcon} alt="" />
                                </Link>
                              </button>
                            )}
                          </div>
                        );
                      } else if (e2.includes("invoice_field_settings")) {
                        value = <div></div>;
                      } else if (e2.includes("image_url")) {
                        return (
                          <td style={{ padding: "10px" }}>
                            <img height="60px" width="60px" src={value} />
                          </td>
                        );
                      }

                      return <td scope="col">{value}</td>;
                    })}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      {/* <MaterialPagination
        count={props.totalCount}
        page={props.page}
        handleChangePage={props.handleChangePage}
        rowsPerPage={props.page}
        handleChangeRowsPerPage={props.handleChangeRowsPerPage}
      /> */}
    </div>
  );
}

export default Tabledata;
